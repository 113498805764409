<template>
    <div class="container">
        <asset-states
                v-if="asset"
                :asset="asset"
                :imei="asset.reference">
        </asset-states>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        props: ['assetId'],

        mounted() {
            this.loadAsset({
                assetId: this.assetId
            });
        },

        computed: {
            ...mapGetters([
                'asset'
            ]),
        },

        methods: {
            ...mapActions([
                'loadAsset'
            ]),
        }
    }
</script>

<style scoped>

</style>